import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SalesLandingPage from '../../components/SalesLandingPage'
import StickyNav from '../../components/Navigation/StickyNav'
import OGImage from '../../images/one-eleven/OneElevenCover.jpg'
import { graphql } from 'gatsby'

const metaTitle = 'Crowdlinker <> Founder Institute Partnership Page'

const metaDescription =
  'Crowdlinker is a leading digital product design and engineering consultancy based out of Toronto and Barcelona. For a limited time, Crowdlinker is offering Founder Institute members and alumni special pricing on any new design and development projects.'

const OneElevenPage = props => {
  const { siteUrl } = props.data.site.siteMetadata
  const { path } = props
  const pageURL = siteUrl + path

  return (
    <Layout>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={pageURL} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="robots" content="noindex" />
        <meta property="og:image" content={siteUrl + OGImage} />
        <meta property="og:image:secure_url" content={siteUrl + OGImage} />
        <meta name="twitter:image" content={siteUrl + OGImage} />
      </Helmet>
      <StickyNav />
      <SalesLandingPage name="Founder Institute" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default OneElevenPage
